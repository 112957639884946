<template>
  <div class="welcome">
    <!-- <p class="upper font12">Welcome</p> -->
    <div class="flex justify-center">
      <!-- <img class="mr-2" src="./../assets/ui/magGlass.svg" alt="">
      <h1 class="text-center font-normal text-6xl">{{title}}</h1> -->
      <img class="w-full max-width" src="../assets/logo/spirespy.svg" alt="">
    </div>
    <p class="font18 mt-6 mx-10 sm:mt-4">{{subtitle}}</p>
  </div>
</template>

<script>
export default {
  name: 'hero',
  props: {
    title: {
      type: String,
      default: 'SpireSpy'
    },
    subtitle: {
      type: String,
      default: 'Slay The Spire tier list and deck builder'
    }
  }
};
</script>

<style lang="scss" scoped>
.welcome {
  padding: 70px 20px 60px;
  // height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


h1 {
  text-shadow: 2px 2px 0 rgba($color: #999124, $alpha: 1),
               5px 5px 0 rgba($color: #000, $alpha: 0.5);
  // -webkit-text-stroke: 2px #807a29;
  // font-weight: 400;
  // text-align: center;
  color: #ede688;
  padding: 0;
  margin: 0;
  line-height: 100%;
  // font-size: 84px;
}
p {
  text-align: center;
  text-shadow: 2px 2px 0 rgba($color: #000, $alpha: 0.5);

}
.upper {
  text-transform: uppercase;
}
.font12 {
  font-size: 14px;
}

.font18 {
  font-size: 24px;
}

.max-width {
  max-width: 400px;
}
</style>
